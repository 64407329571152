import React from 'react';
import './BannerDerImage.css';

const Banner = ({ titulo, descripcion,urlBtn, buttonText,imagenURL, TituloImagen }) => {
  return (
    <div className="BannerDerImagen-container">
      <div className="BannerDerImagen-content">
        <h1 className="BannerDerImagen-title">{titulo}</h1>
        <p className="BannerDerImagen-description">{descripcion}</p>
        <a href={urlBtn} className="BannerDerImagen-button btnColor" target='_Blank'>{buttonText}</a>
      </div>
      <div className="image-container-bannerImageIz">
        <img className='image-bannerImageIz ' src={imagenURL} alt={TituloImagen} />
      </div>
      
    </div>
  );
}

export default Banner;
