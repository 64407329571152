import SeccionMitadDeImagenIZQTXTCentrado from "../component/SeccionMitadImagenIZQTxTCentrado";
import GridTresIcon from "../component/gridTresPartesIconTitulo"
import SeccionMitadDobleTxT from "../component/SeccionMitadTXTCentradoDoble";
import IndustekSection from '../component/TextoCentralBtn';
import Footer from '../component/Footer';
import IconoWhatsapp from "../component/IconoWhatsapp";
import TituloConFondo from "../component/TituloConFondo";
import { Helmet } from "react-helmet";

const ServiciosDeIngenieria = () => {
    return <div>
        <Helmet>
        <title>Servicios De Ingeniería industek Consultoría y Gestión de Proyectos</title>
        <link rel="canonical" href="http://industek.com.co/servicios-de-ingenieria"></link>
        <meta property="og:url" content="http://industek.com.co/servicios-de-ingenieria"></meta>
        <meta itemprop="url" content="http://industek.com.co/servicios-de-ingenieria"></meta>
        <meta name="twitter:url" content="http://industek.com.co/servicios-de-ingenieria"></meta>
        <meta name="description" content="Nos especializamos en servicios de ingeniería pregunta por la consultoria en Servicios de Gestión Integral de Proyectos. Elaboración de Presupuestos. Programación y Control de Obras. Interventoría de Obras. Diseños Estructurales y Arquitectónicos."></meta>
        <meta property="og:site_name" content="Industek Lineas de vida y puntos de anclaje"></meta>
        <meta property="og:title" content="Servicios De Ingeniería industek Consultoría y Gestión de Proyectos"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:description" content="Nos especializamos en servicios de ingeniería pregunta por la consultoria en Servicios de Gestión Integral de Proyectos. Elaboración de Presupuestos. Programación y Control de Obras. Interventoría de Obras. Diseños Estructurales y Arquitectónicos."></meta>
        <meta itemprop="name" content="Servicios De Ingeniería industek Consultoría y Gestión de Proyectos"></meta>
        <meta itemprop="description" content="Nos especializamos en servicios de ingeniería pregunta por la consultoria en Servicios de Gestión Integral de Proyectos. Elaboración de Presupuestos. Programación y Control de Obras. Interventoría de Obras. Diseños Estructurales y Arquitectónicos."></meta>
        <meta name="twitter:title" content="Servicios De Ingeniería industek Consultoría y Gestión de Proyectos"></meta>
        <meta name="twitter:card" content="summary"></meta>
        <meta name="twitter:description" content="Nos especializamos en servicios de ingeniería pregunta por la consultoria en Servicios de Gestión Integral de Proyectos. Elaboración de Presupuestos. Programación y Control de Obras. Interventoría de Obras. Diseños Estructurales y Arquitectónicos."></meta>

        </Helmet>
        <TituloConFondo 
        titulo="Servicios de Ingenieria" 
        imagenFondo="./imagenes/ServicioIngenieria/Fondo.ServicioDeIngenieria2.webp" 
        />
        <SeccionMitadDeImagenIZQTXTCentrado
            imagenURL="./imagenes/ServicioIngenieria/Construccion.webp"
            titulo="Ingeniería en la Construcción"
            descripcion="Asesoramiento especializado en ingeniería para optimizar tus proyectos y tomar decisiones fundamentadas."
            textoBoton="Cotizar Consultoría"
            url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+deseo+realizar+una+cotizaci%C3%B3n+para+la+elaboraci%C3%B3n+de+un+proyecto+de+construcci%C3%B3n."
        />
        <GridTresIcon/>
        <SeccionMitadDobleTxT
            titulo="Diseños Estructurales y Arquitectónicos"
            descripcion="Creación de diseños innovadores y funcionales que cumplen con los requisitos estructurales y estéticos de tu proyecto."
            textoBoton="Cotizar Diseños"
            url="https://api.whatsapp.com/send/?phone=573054138340&text=Buenas+D%C3%ADas+Industek+quiero+conocer+mas+de+dise%C3%B1o+de+estructuras+para+la+elaboraci%C3%B3n+de+una+cotizaci%C3%B3n."
            titulo2="Diseños Estructurales"
        />
        <IndustekSection
        title="INDUSTEK"
        subtitle="Descubre Nuestro Compromiso con la Excelencia"
        description="Nuestra misión es superar tus expectativas en cada proyecto. Desde obras civiles hasta soluciones integrales, estamos comprometidos con la excelencia y la satisfacción del cliente."
        question="¿Listo para dar forma a tu visión?"
        buttonText="Contactar ahora"
        />
        <Footer/>
        <IconoWhatsapp />
    </div>;
}
export default ServiciosDeIngenieria;