import React from 'react';
import './SeccionMitadTXTCentradoDoble.css';

const SeccionConImagen = ({titulo, descripcion, textoBoton, url, titulo2 }) => {
  return (
    <div className="contenedor-seccion-Doble-TxT">
      <div className="lado-derecho-Doble-TxT">
        <h2 className="titulo-seccion-Doble-TxT">{titulo}</h2>
        <p className="descripcion-seccion-Doble-TxT">{descripcion}</p>
        <div>
            <a className='boton-seccion-Doble-TxT btnColor' type="button" href={url}  target='_Blanck'>{textoBoton}</a>
        </div>
      </div>
      <div className="lado-derecho-Doble-TxT">
        <h2 className="titulo2-seccion-Doble-TxT">{titulo2}</h2>
      </div>
    </div>
  );
};

export default SeccionConImagen;