import React from 'react';
import './bannerImageIzq.css'; // Importamos el archivo CSS para los estilos

const CustomContainer = () => {
  return (
    <div className="custom-grid-container-bannerImageIz">
      {/* Imagen que ocupa un tercio */}
      <div className="image-container-bannerImageIz">
        <img src="/imagenes/Inicio/PuntoAnclaje.webp" alt="Producto" className="image-bannerImageIz"/>
      </div>

      {/* Contenido textual que ocupa dos tercios */}
      <div className="content-container-bannerImageIz">
        <h3 className="small-title-bannerImageIz">SERVICIO ESPECIALIZADO</h3>
        <h1 className="title-bannerImageIz">Puntos de Anclaje</h1>
        <p className="description-bannerImageIz">
        Fundamentales para tu seguridad en alturas. Nuestros sistemas garantizan la protección necesaria en cada trabajo. Cumplimos con las regulaciones colombianas y internacionales.
        </p>

        {/* Características */}
        <div className="features-bannerImageIz">
          <div className="feature-bannerImageIz">Cumplimiento normativo</div>
          <div className="feature-bannerImageIz">Brindarte tranquilidad</div>
        </div>

        {/* Botón */}
        <a className="quote-button-bannerImageIz btnColor" href='https://api.whatsapp.com/send/?phone=573054138340&text=Buenos+Dias+estamos+interesados+en+conocer+mas+sobre+Puntos+de+Anclaje+necesitamos+una+cotizaci%C3%B3n.' target='_Blank'>Cotizar</a>
      </div>
    </div>
  );
};

export default CustomContainer;
